import { template as template_9f8d5dabf68a4e9ab24a6fce65aa2cad } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_9f8d5dabf68a4e9ab24a6fce65aa2cad(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
