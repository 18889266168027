import { template as template_603503ed08ba418e8704637a533e7cdd } from "@ember/template-compiler";
const SidebarSectionMessage = template_603503ed08ba418e8704637a533e7cdd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
